<template>
  <div>
    <!-- 段落1 -->
    <img src="@/image/guanyu2_03.jpg" style="width: 100%;">
    <div class="part1">
      <div class="title" style="line-height: normal;">{{$t('aboutUs.title1')}}</div>
      <div class="text common_text">{{$t('aboutUs.text1')}}</div>
    </div>
    <!-- 段落2 -->
    <img src="@/image/guanyu2_05.jpg" style="width: 100%">
    <div class="part2">
      <div class="title" style="font-size: calc(var(--text) + 3px)">{{$t('aboutUs.title2')}}</div>
      <div class="text common_text">{{$t('aboutUs.text2')}}</div>
      <div class="text common_text">{{$t('aboutUs.text3')}}</div>
    </div>
    <!-- 段落3 -->
    <img src="@/image/guanyu2_07.jpg" style="width: 100%">
    <div class="part3">
      <div class="title" style="line-height: normal;">{{$t('aboutUs.title3')}}</div>
      <div class="text common_text" style="margin-top: 10px;">{{$t('aboutUs.text4')}}</div>
    </div>
    <!-- 段落4 -->
    <img src="@/image/guanyu2_09.jpg" style="width: 100%">
    <div class="part4">
      <div class="title" style="line-height: normal;">{{$t('aboutUs.title4')}}</div>
      <div class="text common_text" style="margin-top: 10px;">{{$t('aboutUs.text5')}}</div>
    </div>
    <!-- 段落5 -->
    <!-- <img src="@/image/guanyu_10.jpg" style="width: 100%">
    <div class="part5">
      <div class="title" style="line-height: normal;margin-bottom:10px;">{{$t('aboutUs.title5')}}</div>
      <div class="text common_text" v-for="(item,index) in $t('aboutUs.arr1')" :key="index">{{item}}</div>
    </div> -->
    <!-- 段落6 -->
    <div style="position: relative;">
      <!-- <img src="@/image/guanyu_12.jpg" style="width:100%"> -->
      <div class="part6">
        <div class="title">{{$t('aboutUs.title6')}}</div>
        <div class="bonus flex_row common_text" v-for="(item,index) in $t('aboutUs.arr2')" :key="index">
          <div class="bonus_title">{{item.title}}</div>
          <div class="bonus_text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      
    },
    
  },
};
</script>
<style lang="scss" scoped>
.text{
  padding: 0 15px;
  color: #ffffff;
}
.title{
  padding: 0 15px;
  color: #1CC6BC;
  font-size: var(--title);
  margin-bottom: 10px;
}
.part1{
  padding: 5px 0;
  // background: #fff;
}
.part2{
  padding: 10px 0 50px;
  // background: #7E6312;
}
.part3{
  // background: #D92440;
  padding: 10px 0 50px;
}
.part4{
  // background: #105B0E;
  padding: 10px 0 50px;
}
.part5{
  // background: #000000;
  padding: 10px 0 20px;
}
.part6{
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0 50px;
  .title{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #1CC6BC;
    padding: 0 15px;
    margin: 20px 0;
  }
  .bonus{
    padding: 3px 15px;
    align-items: flex-start;
    .bonus_title{
      color: #1CC6BC;
      width: 25%;
    }
    .bonus_text{
      color: #1CC6BC;
      width: 0;
      flex: 1;
    }
  }
}
</style>